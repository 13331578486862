import React, { FC, useCallback, useState } from "react";
import { ImageProps, StyleProp, ViewStyle, View, ImageStyle } from "react-native";
import Animated, { useAnimatedStyle, withSpring } from "react-native-reanimated";
import { Skeleton } from "@lookiero/aurora";
import { style } from "./LazyImage.style";

interface LazyImageStyle {
  readonly view?: StyleProp<ViewStyle>;
  readonly image?: StyleProp<ImageStyle>;
}

interface LazyImageProps extends Omit<ImageProps, "source" | "src" | "srcSet" | "style"> {
  readonly src: string;
  readonly hiResSrc?: string;
  readonly style?: LazyImageStyle;
}

const LazyImage: FC<LazyImageProps> = ({ src, hiResSrc, style: customStyle, ...restProps }) => {
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [hiResImageLoaded, setHiResImageLoaded] = useState(false);

  const handleOnImageLoaded = useCallback(() => setMainImageLoaded(true), []);

  const handleOnHiResImageLoaded = useCallback(() => setHiResImageLoaded(true), []);

  const mainImageAnimatedStyle = useAnimatedStyle(
    () => ({ opacity: withSpring(mainImageLoaded ? 1 : 0) }),
    [mainImageLoaded],
  );
  const hiResImageAnimatedStyle = useAnimatedStyle(
    () => ({ opacity: withSpring(hiResImageLoaded ? 1 : 0) }),
    [hiResImageLoaded],
  );

  return (
    <View style={[style.view, customStyle?.view]}>
      {!mainImageLoaded && <Skeleton style={style.skeleton} />}
      <Animated.Image
        {...restProps}
        source={{ uri: src }}
        style={[style.image, customStyle?.image, mainImageAnimatedStyle]}
        onLoad={handleOnImageLoaded}
      />
      {mainImageLoaded && hiResSrc && (
        <Animated.Image
          {...restProps}
          source={{ uri: hiResSrc }}
          style={[style.image, style.hiResImage, customStyle?.image, hiResImageAnimatedStyle]}
          onLoad={handleOnHiResImageLoaded}
        />
      )}
    </View>
  );
};

export { LazyImage };
