import { StyleSheet } from "react-native";

// import { theme } from "@lookiero/sty-psp-ui";

// const { space6, space10, layoutMaxWidth } = theme();

const style = StyleSheet.create({
  hiResImage: {
    zIndex: 20,
  },
  image: {
    flex: 1,
    height: "100%",
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },
  skeleton: {
    flex: 1,
  },
  view: {
    position: "relative",
  },
});

export { style };
